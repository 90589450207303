 

document.addEventListener( 'DOMContentLoaded', function () {
        $('ul.tabs').each(function(){
          var active, content, links = $(this).find('a');
          active = links.first().addClass('active');
          content = $(active.attr('href'));
          links.not(':first').each(function () {
            $($(this).attr('href')).hide();
          });
          $(this).find('a').click(function(e){
            active.removeClass('active');
            content.hide();
            active = $(this);
            content = $($(this).attr('href'));
            active.addClass('active');
            content.show();
            return false;
          });
        });
      }, false );
     $(document).ready(function(){       
       $(document).on("click",".qtybox .btnqty",function() {
         var qty = parseInt($(this).parent('.qtybox').find('.quantity-input').val());
         if($(this).hasClass('qtyplus')) {
           qty++;
         }else {
           if(qty > 1) {
             qty--;
           }
         }
         qty = (isNaN(qty))?1:qty;
         $(this).parent('.qtybox').find('.quantity-input').val(qty);
       });
     });